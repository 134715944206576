export const MenuRoutes = [
    {
        title:'Home',
        path:'/'
    }, {
        title:'Market(ERC721)',
        path:'/collectionsv2'
    }, 
    {
        title:'Ecosystem',
        path:'/ecosystem',
        elements:[
            // {title:'ZenoPASS',path:'/membershipspagev2'}, 
            // zenogiving.com
            // zenoreading.com
            // zenoticket.com
            // nftvideo.com
            // rwahubs.com
            {title:'ZenoBundle (ERC1155)',path:'https://www.zenobundle.com/#/collectionsv2', outLink:true},
            {title:'ZenoGiving',path:'/comming'},
            {title:'ZenoReading',path:'/comming'},
            {title:'ZenoTicket',path:'/comming'},
            {title:'NFTVideo',path:'/comming'},
            {title:'RWAHubs',path:'/comming'},
            {title:'More...',path:'/comming'} 
        ]
    }, 
    {
        title:'Studio',
        path:'/studio',
        elements:[
            {title:'ZenoPASS',path:'/membershipspagev2'},
            {title:'My ZenoList',path:'/mywhitelistspagev2'},
            {title:'Create Collection',path:'/createcolletionv2'},
            {title:'Create NFT',path:'/createnftv2'}, 
        ]
    }, 
    {
        title:'About Us',
        path:'https://www.zenobit.io'
    }
]
